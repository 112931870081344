/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/jqueryui@1.11.1/jquery-ui.min.js
 * - /npm/leaflet@1.3.1/dist/leaflet-src.min.js
 * - /npm/leaflet-polylinedecorator@1.6.0/dist/leaflet.polylineDecorator.min.js
 * - /npm/qrcodejs@1.0.0/qrcode.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
